import {
    ProductAssortmentType,
    ProductAvailabilityStatus
} from '~/types';


export function useProductAvailabilityStatus(
    status: Nilish<ProductAvailabilityStatus>,
    assortmentType: Nilish<ProductAssortmentType>
) {

    const statusText = computed<Nilish<string>>(() => {
        if (assortmentType === ProductAssortmentType.Discontinued) {
            return 'Discontinued'
        }

        switch (status) {
            case ProductAvailabilityStatus.Preorder:
                return 'Preorder';
            case ProductAvailabilityStatus.ComingSoon:
                return 'Coming Soon';
            case ProductAvailabilityStatus.Backorder:
                return 'Backorder';
            case ProductAvailabilityStatus.SoldOut:
                return 'Sold Out';
        }

        return;
    });

    const canShowEstShipDate = computed<boolean>(() => {
        switch (status) {
            case ProductAvailabilityStatus.Preorder:
            case ProductAvailabilityStatus.ComingSoon:
            case ProductAvailabilityStatus.Backorder:
                return true;
        }

        return false;
    });

    return {
        statusText,
        canShowEstShipDate
    };
}
